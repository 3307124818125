import { useDispatch } from "react-redux";
import { TitleInformationComponent } from "../../components";
import { getListOptions } from "../../helpers";
import {
  setRetrieveCoupon,
  setUpdateCouponUsers,
} from "../../store/coupon/couponSlice";
import { SelectOptionsContainer } from "../SelectOptionsContainer/SelectOptionsContainer";

export const CouponDetailUserTypeContainer = ({
  listOptionsClients,
  couponData,
}) => {
  const dispatch = useDispatch();

  const handleOnchage = (e) => {
    dispatch(setRetrieveCoupon({ data: { [e.target.name]: e.target.value } }));
  };

  const typeUser = [
    { id: 1, name: "userType", value: 1, label: "Para todos los usuarios" },
    { id: 2, name: "userType", value: 2, label: "Para ciertos usuarios" },
  ];

  const processListOptionsClients = getListOptions([
    listOptionsClients,
    "client",
    "user",
  ]);
  const handleSelectOnchange = (e) => {
    dispatch(setRetrieveCoupon({ data: { [e.name]: e.label } }));
  };

  const handleOnchageMultipleColections = (e) => {
    dispatch(setUpdateCouponUsers({ users: e }));
  };
  return (
    <div className="productDetailInformationContainer">
      <TitleInformationComponent title={"Según tipo de Usuario"} />
      <SelectOptionsContainer
        title={"*Tipo de Usuario"}
        handleOnchange={handleSelectOnchange}
        data={typeUser}
        placeholder={"Seleccionar tipo"}
        isSelect={true}
        valueDefault={couponData?.userType}
      />
      {couponData?.userType === "Para ciertos usuarios" && (
        <SelectOptionsContainer
          title={"Usuarios"}
          handleOnchange={handleOnchageMultipleColections}
          name="users"
          isInputMulti={true}
          value={couponData?.users}
          dataList={processListOptionsClients}
        />
      )}
      <SelectOptionsContainer
        title={"*Cupones máximo a usar"}
        handleOnchange={handleOnchage}
        name="maximumCouponsUse"
        isNumber={true}
        value={couponData.maximumCouponsUse}
      />
    </div>
  );
};
