import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ButtonPushComponent, TitleComponent } from "../../components";
import { ModalLayout } from "../../layouts";
import { requestPostAuthorization } from "../../services/requestPost";
import { clearUpdateCombo } from "../../store/combo";
import { ModalContainer } from "../ModalContainer/ModalContainer";

export const CouponCreateHeaderContainer = () => {
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);

  const { couponData } = useSelector((state) => state.retrieveCoupon);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toggleModalUpdate = () => {
    setIsOpenUpdate(!isOpenUpdate);
  };
  const canceledStateStyle = {
    fontSize: "15px",
    fontWeight: "bold",
  };

  const convertMap = (array) => {
    return array.map((item) => item.id);
  };

  const conbinedString = (date,time) => {
    const combinedString = date + 'T' + time + "-05:00";
    return combinedString;
  }

  const handleCreateCombo = async () => {
    const body = { ...couponData };
    body.description = `${body.name} - ${body.name}`
    body.expirationDate = conbinedString(couponData?.expirationDate,couponData?.expirationDateTime)
    body.startDate = conbinedString(couponData?.startDate,couponData?.startDateTime)
    body?.users && (body.users = convertMap(body.users));
    body?.colections && (body.colections = convertMap(body.colections));
    body?.products && (body.products = convertMap(body.products));
    await requestPostAuthorization(`admin/cupones/`, body).then(() => {
      dispatch(clearUpdateCombo());
      navigate("/cupones");
    });
  };

  return (
    <section className="OrderDetailHeaderContainer">
      <div className="OrderDetailHeaderContainer__title">
        <TitleComponent title="Crear Cupon" />
      </div>
      <div className="OrderDetailHeaderContainer__container-button">
        <ButtonPushComponent name={"Crear Cupon"} onClick={toggleModalUpdate} />
      </div>
      {isOpenUpdate && (
        <ModalLayout>
          <ModalContainer
            title="Crear Cupon"
            msg="¿Está seguro que desea crear el Cupon?"
            style={canceledStateStyle}
            onClose={toggleModalUpdate}
            onConfirm={handleCreateCombo}
          />
        </ModalLayout>
      )}
    </section>
  );
};
