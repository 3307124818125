import { useEffect } from "react";
import DatePicker from "react-multi-date-picker";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { LoadingComponent, ReportTableHeadComponent } from "../../components";
import { apiPath, getFormatDate } from "../../helpers";
import {
  setDateBodyOrderSalesDetail,
  setDateOrderSalesActiveDetail,
  setDateOrderSalesDetail,
} from "../../store/statistics/statisticsSlice";
import { getListOrderClientsDetail } from "../../store/statistics/thunks";

const meses = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dic",
];

const dias = ["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"];

const optionsMonth = [
  { value: "00", label: "-------------" },
  { value: "01", label: "Enero" },
  { value: "02", label: "Febrero" },
  { value: "03", label: "Marzo" },
  { value: "04", label: "Abril" },
  { value: "05", label: "Mayo" },
  { value: "06", label: "Junio" },
  { value: "07", label: "Julio" },
  { value: "08", label: "Agosto" },
  { value: "09", label: "Septiembre" },
  { value: "10", label: "Octubre" },
  { value: "11", label: "Noviembre" },
  { value: "12", label: "Diciembre" },
];

const optionsYear = [
  { value: "2024", label: "2024" },
  { value: "2023", label: "2023" },
  { value: "2022", label: "2022" },
  { value: "2021", label: "2021" },
];

const titles = [
  { text: "Código", rowSpan: 2 },
  { text: "Nombres", rowSpan: 2 },
  { text: "Email", rowSpan: 2 },
  { text: "Celular", rowSpan: 2 },
  { text: "Ordenes por tienda", colSpan: 2 },
  { text: "Ordenes por modalidad", colSpan: 2 },
  { text: "Venta por tienda", colSpan: 2 },
  { text: "Venta por modalidad", colSpan: 2 },
  { text: "Total de pedidos", rowSpan: 2 },
  { text: "Total de ventas", rowSpan: 2 },
  { text: "Última fecha de compra", rowSpan: 2 },
];

const headRows = [
  { text: "Miraflores" },
  { text: "Pueblo Libre" },
  { text: "Delivery" },
  { text: "Recojo en tienda" },
  { text: "Miraflores" },
  { text: "Pueblo Libre" },
  { text: "Delivery" },
  { text: "Recojo en tienda" },
];

export const ReportsOrderClientContainer = () => {
  const { bodyDate, date, isActive } = useSelector(
    (state) => state.dateOrderSalesDetail
  );
  const { dataOrderClientsDetail, showLoading } = useSelector(
    (state) => state.listOrderClientsDetail
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getListOrderClientsDetail(bodyDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bodyDate.startDate, bodyDate.endDate]);

  const TableGrafic = () => {
    const styles = {
      row: "reportTableHeadComponent__body__row",
      text: "reportTableHeadComponent__body__row__text",
      textWith: "reportTableHeadComponent__body__row__text reportWith",
    };
    return (
      <table border="1" className="reportTableHeadComponent">
        <ReportTableHeadComponent titles={titles} headRows={headRows} />
        <tbody>
          {dataOrderClientsDetail?.map((item, index) => {
            const date = new Date(item.lastPurchaseDate);
            const lastPurchaseDate = getFormatDate(date);
            return (
              <tr className={styles.row} key={index}>
                <td className={styles.text}>{item.code}</td>
                <td className={styles.textWith}>{item.fullName}</td>
                <td className={styles.text}>{item.email}</td>
                <td className={styles.text}>{item.phone}</td>
                <td className={styles.text}>{item.totalOrdersShopOne}</td>
                <td className={styles.text}>{item.totalOrdersShopTwo}</td>
                <td className={styles.text}>{item.totalOrdersDelivery}</td>
                <td className={styles.text}>{item.totalOrdersStorePickup}</td>
                <td className={styles.text}>{item.totalSalesShopOne}</td>
                <td className={styles.text}>{item.totalSalesShopTwo}</td>
                <td className={styles.text}>{item.totalSalesDelivery}</td>
                <td className={styles.text}>{item.totalSalesStorePickup}</td>
                <td className={styles.text}>{item.totalOrders}</td>
                <td className={styles.text}>{item.totalSales.toFixed(2)}</td>
                <td className={styles.textWith}>{lastPurchaseDate}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const exportExcel = () => {
    const token = localStorage.getItem("token");
    apiPath
      .post("admin/estadisticas/exportar_clientes/", bodyDate, {
        headers: {
          Authorization: `Token ${token}`,
        },
        responseType: "arraybuffer",
      })
      .then((value) => {
        const myBlob = new Blob([value.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const fileUrl = URL.createObjectURL(myBlob);
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = "ventas-clientes.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  };

  function handleOnchange(year, month) {
    const newDate = {
      year,
      month,
    };
    let bodyDate = "";
    if (month === "00") {
      bodyDate = {
        startDate: `${year}-01-31`,
        endDate: `${year}-12-31`,
      };
      dispatch(setDateBodyOrderSalesDetail(bodyDate));
      dispatch(setDateOrderSalesDetail(newDate));
      return;
    }
    const firstDayOfMonth = new Date(year, month - 1, 1);
    const firstDayOfNextMonth = new Date(year, month, 1);
    const lastDayOfMonth = new Date(firstDayOfNextMonth - 1);
    dispatch(setDateOrderSalesDetail(newDate));
    const dayStr2 = firstDayOfMonth.getDate().toString().padStart(2, "0");
    const dayStr = lastDayOfMonth.getDate().toString().padStart(2, "0");
    bodyDate = {
      startDate: `${year}-${month}-${dayStr2}`,
      endDate: `${year}-${month}-${dayStr}`,
    };
    dispatch(setDateBodyOrderSalesDetail(bodyDate));
  }

  const handleDataPicker = (e) => {
    if (e.length >= 2) {
      let valueMonthFirst = e[0].monthIndex + 1;
      let valueMonthSecond = e[1].monthIndex + 1;

      let valueDayFirst = e[0].day;
      let valuedaySecond = e[1].day;

      if (valueMonthFirst < 10) {
        valueMonthFirst = `0${valueMonthFirst}`;
      }
      if (valueMonthSecond < 10) {
        valueMonthSecond = `0${valueMonthSecond}`;
      }
      if (e[0].day < 10) {
        valueDayFirst = `0${valueDayFirst}`;
      }
      if (e[1].day < 10) {
        valuedaySecond = `0${valuedaySecond}`;
      }

      const startDate = `${e[0].year}-${valueMonthFirst}-${valueDayFirst}`;
      const endDate = `${e[1].year}-${valueMonthSecond}-${valuedaySecond}`;
      const body = {
        startDate,
        endDate,
      };
      dispatch(setDateBodyOrderSalesDetail(body));
    }
  };

  return (
    <>
      <div className="homeDashboardContainer">
        <div className="homeDashboardContainer__container-title">
          <h6 className="homeDashboardContainer__container-title__title">
            Ventas por Clientes
          </h6>
          <div className="homeDashboardContainer__container-title__button">
            <button onClick={exportExcel}>Descargar</button>
          </div>
        </div>
        <div className="homeDashboardContainer__container-filter">
          <div className="homeDashboardContainer__container-filter__left">
            <div className="homeDashboardContainer__container-filter__left__filter">
              <Select
                isDisabled={isActive.active}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? "blue" : "#ebeef2",
                    borderRadius: "14px",
                    padding: "3px 7px ",
                    width: 200,
                  }),
                }}
                placeholder={"Eliga el mes"}
                options={optionsMonth}
                onChange={(e) => handleOnchange(date.year, e.value)}
                defaultValue={{
                  label: date.month === "00" ? "Seleccione Mes" : date.month,
                }}
              />
            </div>
            <div className="homeDashboardContainer__container-filter__left__filter">
              <Select
                isDisabled={isActive.active}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? "blue" : "#ebeef2",
                    borderRadius: "14px",
                    padding: "3px 7px ",
                    width: 200,
                  }),
                }}
                options={optionsYear}
                onChange={(e) => handleOnchange(e.value, date.month)}
                defaultValue={{ label: date.year }}
              />
            </div>
            <div className="homeDashboardContainer__container-filter__left__filter dashboard-check">
              <input
                type={"checkbox"}
                onChange={(e) =>
                  dispatch(setDateOrderSalesActiveDetail(e.target.checked))
                }
              />
              <label>Filtrar por día</label>
            </div>
          </div>
          <div className="homeDashboardContainer__container-filter__right">
            <DatePicker
              style={{
                opacity: isActive.active ? 1 : 0,
                borderRadius: "14px",
                padding: "3px 7px",
                width: 200,
                height: 40,
                textAlign: "center",
              }}
              months={meses}
              weekDays={dias}
              onChange={handleDataPicker}
              range
              rangeHover
              format="DD/MM/YYYY"
              placeholder={`Filtrar por dias`}
            />
          </div>
        </div>
        <div className="homeDashboardContainer__container-table reportClient">
          {showLoading ? <TableGrafic /> : <LoadingComponent />}
        </div>
      </div>
    </>
  );
};
