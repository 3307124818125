const fncPhoto = async (data) => {
  const formDataPhoto = new FormData();
  let index = 0;
  if (!!data.photos.length) {
    for (const item of data.photos) {
      if (item.photo.url) {
        let blob = await fetch(item.photo.url).then((r) => r.blob());
        const file = new File([blob], item.photo.name, {
          type: item.photo.type,
        });
        formDataPhoto.append(`photos[${index}]photo`, file);
      }
      item.id && formDataPhoto.append(`photos[${index}]id`, item.id);
      formDataPhoto.append(`photos[${index}]name`, item.name);
      index++;
    }
  } else {
    formDataPhoto.append("photos[]", []);
  }

  return formDataPhoto;
};

const fncShedules = async (data) => {
  let formDataPhoto = new FormData();
  let index = 0;
  if (!!data.shedules.length) {
    for (const item of data.shedules) {
      item.id && formDataPhoto.append(`shedules[${index}]id`, item.id);
      formDataPhoto.append(`shedules[${index}]day`, item.day);
      formDataPhoto.append(`shedules[${index}]startTime`, item.startTime);
      formDataPhoto.append(`shedules[${index}]endTime`, item.endTime);
      formDataPhoto.append(`shedules[${index}]active`, item.active);
      index++;
    }
  } else {
    formDataPhoto.append("shedules[]", []);
  }
  return formDataPhoto;
};
const fncInitial = async (data) => {
  const formDataPhoto = new FormData();
  const photoFields = [
    { fieldName: "image", dataField: "image" },
    { fieldName: "cover", dataField: "cover" },
    { fieldName: "coverMobile", dataField: "coverMobile" },
    { fieldName: "cart", dataField: "cart" },
  ];
  for (const field of photoFields) {
    if (data[field.dataField].url) {
      let blob = await fetch(data[field.dataField].url).then((r) => r.blob());
      const file = new File([blob], data[field.dataField].name, {
        type: data[field.dataField].type,
      });
      formDataPhoto.append(field.fieldName, file);
    }
  }
  formDataPhoto.append("name", data.name);
  formDataPhoto.append("slug", data.slug);
  formDataPhoto.append("description", data.description);
  formDataPhoto.append("adress", data.adress);
  formDataPhoto.append("maps", data.maps);
  formDataPhoto.append("active", data.active);
  return formDataPhoto;
};

const fncShedulesModality = async (data) => {
  const formDataPhoto = new FormData();
  if (!!data.metodosEntregasTiendas.length) {
    let index = 0;
    for (const item of data.metodosEntregasTiendas) {
      item.id &&
        formDataPhoto.append(`metodosEntregasTiendas[${index}]id`, item.id);
      formDataPhoto.append(
        `metodosEntregasTiendas[${index}]modality`,
        item.modality
      );
      formDataPhoto.append(
        `metodosEntregasTiendas[${index}]timeOpening`,
        item.timeOpening
      );
      formDataPhoto.append(
        `metodosEntregasTiendas[${index}]timeClosing`,
        item.timeClosing
      );
      formDataPhoto.append(`metodosEntregasTiendas[${index}]price`, item.price);
      formDataPhoto.append(
        `metodosEntregasTiendas[${index}]active`,
        item.active
      );
      index++;
    }
  } else {
    formDataPhoto.append("metodosEntregasTiendas[]", []);
  }
  return formDataPhoto;
};

const fncSchedulesPickUp = async (data) => {
  const formDataPhoto = new FormData();
  let index = 0;
  if (!!data.horariosRecojos.length) {
    for (const item of data.horariosRecojos) {
      item.id && formDataPhoto.append(`horariosRecojos[${index}]id`, item.id);
      formDataPhoto.append(
        `horariosRecojos[${index}]startTime`,
        item.startTime
      );
      formDataPhoto.append(`horariosRecojos[${index}]endTime`, item.endTime);
      formDataPhoto.append(`horariosRecojos[${index}]active`, item.active);
      index++;
    }
  } else {
    formDataPhoto.append(`horariosRecojos[]`, []);
  }
  return formDataPhoto;
};

export async function createFormData(storeData) {
  const [
    formDataPhotos,
    formDataShedules,
    formDataInitial,
    formDataModality,
    formDataSchedulesPickUp,
  ] = await Promise.all([
    fncPhoto(storeData),
    fncShedules(storeData),
    fncInitial(storeData),
    fncShedulesModality(storeData),
    fncSchedulesPickUp(storeData),
  ]);

  const formData = new FormData();
  [
    formDataPhotos,
    formDataShedules,
    formDataInitial,
    formDataModality,
    formDataSchedulesPickUp,
  ].forEach((form) => {
    for (const [key, value] of form.entries()) {
      formData.append(key, value);
    }
  });

  return formData;
}

export async function processPhotoFormData(data, formData, photoFields) {
  for (const field of photoFields) {
    if (
      data[field.dataField]?.url &&
      (data[field.dataField]?.url.includes("blob:http:/") || data[field.dataField]?.url.includes("blob:https:/"))
    ) {
      let blob = await fetch(data[field.dataField].url).then((r) => r.blob());
      const file = new File([blob], data[field.dataField].name, {
        type: data[field.dataField].type,
      });
      formData.append(field.fieldName, file);
    }
  }
}

export async function processPhotosArray(
  data,
  formData,
  photoFieldName,
  fiel1,
  field2
) {
  let indexPhoto = 0;
  for (const photo of data) {
    if (photo?.url && photo?.url.includes("blob:http:/")) {
      let blob = await fetch(photo.url).then((r) => r.blob());
      const file = new File([blob], photo.name, {
        type: photo.type,
      });
      formData.append(`${photoFieldName}[${indexPhoto}]${fiel1}`, file);
      formData.append(`${photoFieldName}[${indexPhoto}]${field2}`, photo.slug);
    }
    indexPhoto++;
  }
}

export function processTextFormData(
  data,
  formData,
  fieldName,
  descriptionFieldName,
  slugFieldName
) {
  for (let i = 0; i < data.length; i++) {
    const { slug, description } = data[i];
    formData.append(`${fieldName}[${i}]${slugFieldName}`, slug);
    formData.append(`${fieldName}[${i}]${descriptionFieldName}`, description);
  }
}
